body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: black;
  overflow-x: hidden;
  /* Prevent horizontal scrolling */
  width: 100%;
  position: relative
}

html {
  overflow-x: hidden;
}


.container {
  position: relative;
  width: 100%;
  height: 100%;
  /* Increased height to allow scrolling */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  /* Ensure container elements don't exceed viewport width */
  overflow-x: hidden;
}

.portrait-container {
  position: fixed;
  transform: translate(0, -40%);
  z-index: 1;
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

.portrait {
  width: 100%;
  height: 100%;
}

.foreground {
  position: relative;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.foreground-image-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.foreground-image {
  width: 100%;
  /* Ensure the image fills the width of the screen */
  height: auto;
  /* Maintain aspect ratio */
}

.text-container {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.title-image {
  position: relative;
  top: 20%;
  scale: 80%;
  bottom: 20px;
  width: 100%;
  height: auto;
}

.title-quarter {
  z-index: 4;
}

.title-life {
  z-index: 5;
}

.title-crisis {
  z-index: 6;
}

.subtitle {
  position: absolute;
  bottom: 10px;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  /* Optional: If you want the text inside to be centered */
}

.animated-text-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  /* Adds space between each text item */
}


.josefin-sans-light-300 {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}


/* App.css */
.itinerary-background {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000000;
  z-index: 1;
  /* Ensure it is behind the grain overlay */
}

.itinerary-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  /* Ensure it grows with its content */
  max-width: 100%;
  text-align: center;
  font-size: 5rem;
  margin-bottom: 20px;
  color: rgb(255, 255, 255);
  position: relative;
  z-index: 12;
  /* Enable hardware acceleration */
}

.itinerary-list {
  display: flex;
  flex-direction: column;
}

.itinerary-container-title {
  padding-top: 2vh;
  color: white;
  font-size: 10vw;
}

.itinerary-item {
  display: flex;
  flex-direction: column;
  /* Default to row for larger screens */
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 10vw;
  transition: transform 0.3s ease, background-color 0.3s ease;
  z-index: 13;
}

.itinerary-item.left .itinerary-image-container {
  order: 1;
  /* Image on the left */
}

.itinerary-item.left .itinerary-details {
  order: 2;
}

.itinerary-item.right .itinerary-image-container {
  order: 2;
  /* Image on the right */
}

.itinerary-item.right .itinerary-details {
  order: 1;
}

.itinerary-item h1 {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 20px;
  font-family: 'Josefin Sans', sans-serif;
}

.itinerary-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-family: 'Josefin Sans', sans-serif;
}

.itinerary-times {
  display: flex;
  max-width: 40%;
  min-width: 30%;
  flex-direction: column;
  padding: 0 8vw;
  font-family: 'PunkLight2', sans-serif;
}

.itinerary-times p {
  margin: 0;
  color: #ffffff;
  font-size: 8vw;
  text-align: center;
}

a {
  color: hotpink;
  text-decoration: underline;
}

.rsvp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: 15vh;
}


.rsvp-button {
  background-color: white;
  color: black;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 3rem;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 50vw;
  box-shadow: 0 4px 6px black;
  transition: background-color 0.3s ease, color 0.3s ease, outline 0.3s ease;
  outline: 2px solid rgb(0, 0, 0);
}



.rsvp-button:hover {
  background-color: black;
  color: white;
  outline: 2px solid white;
  /* Add an outline on hover */
}



.divider {
  width: 100%;
  height: 1px;
  /* Adjust the height as needed */
  background-color: rgb(255, 255, 255);
  /* Divider color */
  margin: 5px 0;
  /* Adjust the margin as needed */
}


.itinerary-image-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  box-shadow: 10px 5px 5px black;
  z-index: 20;
  max-width: 60%;
  min-width: 40%;
}

.itinerary-image-container ::before {
  content: '';
  display: block;
}

.itinerary-image-container img {
  z-index: 20;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  /* Ensures the image covers the container while maintaining aspect ratio */
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.modal {
  background: white;
  padding: 2vw;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  width: 90%;
  max-width: 600px;
  font-family: 'AlteHaas', sans-serif;
  font-weight: bold;
}

.modal h2 {
  font-size: 5rem;
  margin-bottom: 1vw;
  align-items: center;
  width: 100%;
  text-align: center;

}

.modal form {
  display: flex;
  flex-direction: column;
}

.modal .form-group {
  display: flex;
  align-items: center;
  margin-bottom: 3vw;
}
.modal input[type=text]{
  flex: 4;
}

.modal .form-group.label-checkbox {
  justify-content: space-between;
}

.modal label {
  font-size: 1.5rem;
  flex: 3;
  margin-right: 2vw;
  display: flex;
  align-items: center;
}

.modal input,
.modal select {
  font-size: 2rem;
  padding: 0.5vw;
  flex: 2;
  width: 100%;
  /* Ensure inputs use all available horizontal space */
  box-sizing: border-box;
  /* Include padding and border in element's total width */
}

.modal input[type="checkbox"] {
  transform: scale(1.5);
  flex: 0;
  /* Ensure the checkbox does not take extra space */
  width: auto;
  /* Ensure the checkbox keeps its default size */
}

.modal button {
  font-size: 3rem;
  padding: 1vw 2vw;
  cursor: pointer;
  margin-top: 1vw;
  width: 100%;
}

.modal button[type="submit"] {
  background-color: #000000;
  color: white;
  border: none;
}

.modal button[type="button"] {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: 1rem solid rgb(0, 0, 0) 1px;
}

.event-details-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  align-items: center;
}

.event-details {
  flex: 2;
  padding: 20px;
}

.event-details-address{
  font-size: 4vw;
  /* Adjust size as needed */
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  text-decoration: underline;
}

.date {
  font-size: 6vw;
  /* Adjust size as needed */
  font-weight: bold;
  margin-bottom: 10px;
}

.time {
  font-size: 3vw;
  margin-bottom: 10px;
}

.dress-code {
  font-size: 3vw;
  /* Adjust size as needed */
  margin-bottom: 5px;
  font-style: italic;
}

.map {
  width: 100%;
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  
}

iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {

  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {

  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.device-warning {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  padding: 10px;
  z-index: 1000;
  font-size: 18px;
  font-weight: bold;
}

.rsvp-title {
  position: relative;
  margin-bottom: 1vw;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  /* Optional: If you want the text inside to be centered */
}